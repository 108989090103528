<template>
  <div class="concept">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
        <router-link to="/services/gpt-concept/concept/new">
          <el-button icon="el-icon-plus" size="small" type="primary">{{
            $t("common.generate")
          }}</el-button>
        </router-link>
      </div>
    </div>
    <el-alert :title="$t('gpt.noticeInfo')" type="info" show-icon class="alert-content">
    </el-alert>
    <el-alert :title="$t('gpt.noticeWarningConcept')" type="warning" show-icon class="alert-content">
    </el-alert>
    <el-card shadow="never" class="preview">
      <div slot="header">
        <span class="preview-title">Recently created AI "Concept"</span>
        <el-button icon="el-icon-download" class="preview-download excel-dl-btn" size="mini"
          @click="handlePreviewDownload()">{{ $t("common.downloadAll") }}</el-button>
      </div>
      <div class="preview-content">
        <p v-if="loading" class="data-loading">
          <i class="el-icon-loading" />&nbsp;{{ $t("common.loading") }}...
        </p>
        <p v-else-if="!previewData || previewData.length === 0" class="data-loading">
          {{ notice ? notice : $t("common.noMoreData") }}
        </p>
        <el-row v-else :gutter="10">
          <el-col class="data-block" :lg="{ span: '5-24' }" v-for="item in previewData" :key="item.url">
            <div class="data-block-content">
              <el-popconfirm :title="$t('gpt.comfirmReport')" @confirm="handleBlock(item.id)">
                <template #reference>
                  <el-tooltip class="item" effect="dark" :content="$t('gpt.report')" placement="top">
                    <el-button class="block-button" type="danger" plain circle>
                      <svg-icon icon-class="block-btn" />
                    </el-button>
                  </el-tooltip>
                </template>
              </el-popconfirm>
              <el-image class="content-image" :src="item.url | imageUrl(false)"
                :preview-src-list="[imageUrl(item.url, false)]" fit="contain" />
              <div class="data-block-text">
                <el-row class="content-line">
                  # {{ item.desc }}
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column :label="$t('gpt.desc')" min-width="120">
        <template slot-scope="scope">
          {{ scope.row.desc ? scope.row.desc : "-" }}
        </template>
      </el-table-column>
      <el-table-column :label="$t('gpt.status')" min-width="60">
        <template slot-scope="scope">
          <el-tag :type="scope.row | statusFilter" effect="dark" size="small">{{
            scope.row.status === "waiting"
              ? $t("tagger.waiting")
              : scope.row.status === "running"
                ? $t("tagger.running")
                : scope.row.status === "done"
                  ? $t("tagger.done")
                  : scope.row.status === "abort"
                    ? $t("tagger.abort")
                    : scope.row.status === "error"
                      ? $t("tagger.error")
                      : scope.row.status
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('gpt.execAt')" min-width="135">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.execAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('gpt.finishAt')" min-width="135">
        <template slot-scope="scope">
          <i class="el-icon-time"></i>
          <span style="margin-left: 10px">{{
            scope.row.finishAt | displayTime()
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="imageCount" :label="$t('gpt.imageCount')" min-width="80"></el-table-column>
      <el-table-column :label="$t('common.detail')" min-width="60">
        <template slot-scope="scope">
          <router-link :to="'/services/gpt-concept/concept/' + scope.row.id">
            <el-button type="primary" icon="el-icon-more" circle size="small"></el-button>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.delete')" min-width="60">
        <template slot-scope="scope">
          <el-popconfirm :title="$t('tagger.deleteTask')" @confirm="deleteTask(scope.row.id)">
            <el-button type="danger" icon="el-icon-delete" circle size="small" slot="reference"></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column :label="$t('tagger.restart')" min-width="60">
        <template slot-scope="scope">
          <el-button type="warning" icon="el-icon-refresh-left" circle size="small"
            @click="restart(scope.row.id)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination v-show="total > 0" :total="total" :offset.sync="listQuery.offset" :limit.sync="listQuery.limit"
      layout="sizes, prev, pager, next, total " @pagination="fetchData" />
    <el-dialog :title="$t('common.downloading')" width="40%" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false" :visible.sync="dialogVisible" :before-close="downloadClick">
      <p>
        {{
            isDownloading
              ? $t("analysis.downloadImages", {
                finishCount,
                totalCount: previewData.length,
              })
              : $t("analysis.finishDownload")
          }}
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="downloadClick">{{
            isDownloading ? $t("common.abort") : $t("common.ok")
          }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchLatestTask,
  fetchImages,
  fetchTasks,
  restartTask,
  deleteTask,
  blockImage,
} from "@/api/gpt";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Pagination from "@/components/Pagination";
import Guide from "@/components/Guide";
import { imageUrl } from "@/filters";
import { downloadFile } from "@/utils/download-file";

export default {
  components: {
    AiBreadcrumb,
    Pagination,
    Guide,
  },
  data() {
    return {
      previewData: [],
      notice: "",
      loading: false,
      timer: null,
      tableData: [],
      listQuery: {
        offset: 0,
        limit: 10,
      },
      total: 0,
      finishCount: 0,
      dialogVisible: false,
      isDownloading: false,
    };
  },
  mounted() {
    this.fetchList();
    this.fetchData();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  filters: {
    statusFilter(task) {
      if (!task) {
        return "info";
      }
      const statusMap = {
        waiting: "warning",
        running: "",
        done: "success",
        abort: "info",
        error: "danger",
      };
      return statusMap[task.status];
    },
  },
  methods: {
    fetchList() {
      this.previewData = [];
      this.notice = "";
      fetchLatestTask({ category: "concepter" }).then((response) => {
        if (response.success) {
          if (response.result && response.result.id) {
            if (
              response.result.status === "done" ||
              response.result.status === "abort"
            ) {
              clearTimeout(this.timer);
              fetchImages({ category: "concepter", taskId: response.result.id, limit: 5 }).
                then(
                  (response) => {
                    if (response.success) {
                      this.previewData = response.result.items || [];
                    }
                  }
                );
            } else if (
              response.result.status === "waiting" ||
              response.result.status === "running"
            ) {
              this.notice = this.$t('gpt.runningInfo');
              this.timer = setTimeout(this.fetchList, 4000);
            } else if (response.result.status === "error") {
              this.notice = "Task error: " + response.result.errInfo;
              clearTimeout(this.timer);
            }
          } else {
            this.notice = "No task yet";
            clearTimeout(this.timer);
          }
        }
      });
    },
    handlePreviewDownload() {
      this.isDownloading = true;
      this.dialogVisible = true;
      const doDownload = (i) => {
        if (this.isDownloading && this.previewData.length > i) {
          this.finishCount = i;
          const url = this.previewData[i].url;
          downloadFile(
            imageUrl(url, false),
            url ? url.substring(url.lastIndexOf("/") + 1) : "",
            () => {
              doDownload(i + 1);
            }
          );
        } else {
          this.finishCount = 0;
          this.isDownloading = false;
        }
      };
      doDownload(0);
    },
    downloadClick() {
      this.isDownloading = false;
      this.dialogVisible = false;
      this.finishCount = 0;
    },
    restart(id) {
      restartTask(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: this.$t("tagger.restartSucc"),
            type: "success",
          });
          this.fetchData();
          this.fetchList();
        }
      });
    },
    deleteTask(id) {
      deleteTask(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: this.$t("common.deleteSucc"),
            type: "success",
          });
          this.fetchData();
          this.fetchList();
        }
      });
    },
    handleBlock(id) {
      blockImage(id).then((response) => {
        if (response.success) {
          this.$notify({
            title: this.$t("common.saveSucc"),
            type: "success",
          });
          this.fetchList();
        }
      });
    },
    fetchData() {
      fetchTasks({ ...this.listQuery, category: "concepter" }).then((response) => {
        if (response.success) {
          this.tableData = response.result.items || [];
          this.total = response.result.totalCount;
        }
      });
    },
    imageUrl(url, isExternal) {
      return imageUrl(url, isExternal);
    },
    sexFilter(sex) {
      const sexMap = {
        W: "women",
        M: "man",
        CW: "child girl",
        CM: "child body",
      }
      if (sexMap[sex]) {
        return this.$t('gpt.' + sexMap[sex])
      }
      return sex
    }
  },
};
</script>

<style lang="scss" scoped>
.concept {
  .el-col-lg-5-24 {
    width: 1/5 * 100%;
  }

  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;

    .guide-button {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .preview {
    margin-bottom: 10px;
    text-align: center;

    .preview-title {
      line-height: 27.5px;
    }

    .preview-download {
      right: 0;
      position: absolute;
    }

    .el-card {
      border: none;
      border-radius: 0;
    }

    /deep/ .el-card__header {
      padding: 5px;
      font-weight: bold;
      border: none;

      div {
        position: relative;
      }
    }

    /deep/ .el-card__body {
      padding: 0;
      font-size: 12px;
    }

    .preview-content {
      overflow: hidden;
      font-size: small;
      margin-bottom: -10px;
      text-align: left;

      .data-loading {
        text-align: center;
        font-size: 14px;
        color: #606266;
        margin-top: 10px;
        margin-bottom: 20px;
      }

      .data-block {
        margin-bottom: 10px;

        .data-block-content {
          background-color: #fff;
          padding-bottom: 5px;
          position: relative;

          .block-button {
            position: absolute;
            z-index: 1;
            top: 5px;
            right: 5px;
            font-size: xx-large;
            padding: 4px 2px 0;
            border: none;
          }

          .content-image {
            width: 100%;
            height: 0;
            padding-bottom: 100%;

            /deep/ img {
              position: absolute;
              margin-bottom: 5px;
            }
          }

          .data-block-text {
            margin-top: 5px;
            text-align: center;
          }

          .content-line {
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: rgba(0, 0, 0, 0.65);

            .content-label {
              color: #a9a9a9;
              margin-right: 5px;
              line-height: 27px;
            }

            .content-link {
              margin-top: 5px;
              margin-right: 5px;
              line-height: 27px;
            }
          }
        }
      }
    }
  }

  .alert-content {
    margin-bottom: 10px;
    white-space: pre-line;
  }
}
</style>